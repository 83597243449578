import React, { useContext } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import locale from "../../locale/en"
import {
  CalculatorLayout,
  CalculatorInput,
  CalculatorTotal,
  CalculatorButtons,
  CalculatorResultsBox,
  CalculatorGuidelines,
} from "../../components/calculator"
import { sections as faqSections } from "../../components/faq-content/hand-sanitizer"
import Fieldset from "../../components/fieldset"
import { useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import logger from "../../lib/logger"
import { generateOnSubmit } from "../../lib/calculator-sections"
import { CalculatorContext } from "../../providers/calculator-provider"
const { calculator, faq } = locale
const log = logger.extend("calculator:hand-sanitizer")

const SECTION_KEY = "hand_sanitizer"

const RESET_KEYS = [
  "hand_sanitizer_uses_per_customer",
  "hand_sanitizer_uses_per_employee",
]

const CalculatorDisinfectants = () => {
  const { register, errors, handleSubmit } = useForm()
  const onSubmit = generateOnSubmit(SECTION_KEY)
  const { calculatorData } = useContext(CalculatorContext)

  const hasErrors = !!Object.keys(errors).length
  const { number_of_customers_per_week } = calculatorData

  if (hasErrors) {
    log("Form errors:", errors)
  }
  return (
    <Layout>
      <SEO title={`${calculator.title} - ${faq.hand_sanitizer}`} />
      <CalculatorLayout sectionKey={SECTION_KEY}>
        <h2 className="text-black mb-5">{faq.hand_sanitizer}</h2>

        <p className="text-sm mb-5">
          <strong>Assumption</strong>: One pump is equivalent to 1.7 mL, or one
          pea sized dollop of sanitizer.
        </p>

        <CalculatorResultsBox>
          <div>
            <CalculatorTotal totalKey="hand_sanitizer_customer_total_oz" />
            <div className="text-sm">ounces of sanitizer for customers</div>
          </div>
          <div>
            <CalculatorTotal totalKey="hand_sanitizer_employee_total_oz" />
            <div className="text-sm"> ounces of sanitizer for employees</div>
          </div>
        </CalculatorResultsBox>

        <form className="container mt-10" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex gap-2 mb-5 justify-between">
            <div className="w-1/2">
              <h3 className="text-black mb-4">Inputs</h3>
              <fieldset className="mb-5">
                On a <strong>weekly</strong> basis, each customer uses:
              </fieldset>
              <Fieldset
                errorMessage={
                  <ErrorMessage
                    errors={errors}
                    name="hand_sanitizer_uses_per_customer"
                  />
                }
              >
                <CalculatorInput
                  type="number"
                  name="hand_sanitizer_uses_per_customer"
                  disabled={number_of_customers_per_week === 0}
                  className="w-16 mr-5"
                  register={register({
                    min: {
                      value: 0,
                      message: "Must be 0 or greater",
                    },
                  })}
                />
                <label htmlFor="hand_sanitizer_uses_per_customer">
                  pumps of sanitizer
                  {number_of_customers_per_week === 0 ? "*" : null}
                </label>
              </Fieldset>

              {number_of_customers_per_week === 0 ? (
                <div className="text-gray-400 text-sm mb-5">
                  *You entered 0 for number of customers in "Begin"
                </div>
              ) : null}

              <p className="mb-5">and each employee uses:</p>
              <Fieldset
                errorMessage={
                  <ErrorMessage
                    errors={errors}
                    name="hand_sanitizer_uses_per_employee"
                  />
                }
              >
                <CalculatorInput
                  type="number"
                  name="hand_sanitizer_uses_per_employee"
                  className="w-16 mr-5"
                  register={register({
                    min: {
                      value: 0,
                      message: "Must be 0 or greater",
                    },
                  })}
                />
                <label htmlFor="hand_sanitizer_uses_per_employee">
                  pumps of sanitizer
                </label>
              </Fieldset>
              <CalculatorButtons
                register={register}
                sectionKey={SECTION_KEY}
                resetKeys={RESET_KEYS}
              />
            </div>
            <CalculatorGuidelines sections={faqSections} />
          </div>
        </form>
      </CalculatorLayout>
    </Layout>
  )
}

export default CalculatorDisinfectants
