import React from "react"
import Content from "./content"
import { OutboundLink } from "gatsby-plugin-gtag"

export const sections = [
  {
    heading: "Where should I place hand sanitizers?",
    body: (
      <>
        <p>
          <OutboundLink href="https://www.cdc.gov/coronavirus/2019-ncov/community/guidance-business-response.html">
            Hand sanitizing stations
          </OutboundLink>{" "}
          should be installed at entrances and exits as well as all locations
          where workers or employees will be touching shared equipment, such as
          water coolers or check-out stations, at a minimum.
        </p>
      </>
    ),
  },
  {
    heading: "How should hand sanitizer be used?",
    body: (
      <>
        <p>
          According to the{" "}
          <OutboundLink href="https://www.cdc.gov/handwashing/show-me-the-science-hand-sanitizer.html">
            CDC
          </OutboundLink>
          , hand washing with clean water, hand soap, and towels is preferable
          to the use of hand sanitizers when possible; hand sanitizers do not
          work as well when hands are visibly greasy or dirty.
        </p>
      </>
    ),
  },
  {
    heading: "What should you look for when shopping for hand sanitizer?",
    body: (
      <>
        <p>
          As per the{" "}
          <OutboundLink href="https://www.cdc.gov/handwashing/show-me-the-science-hand-sanitizer.html">
            CDC
          </OutboundLink>
          , hand sanitizers should have a 60-95% alcohol concentration.
          Sanitizers that are not alcohol-based or with a lower alcohol
          concentration are not as effective.
        </p>
      </>
    ),
  },
]

const HandSanitizerContent = () => <Content sections={sections} />

export default HandSanitizerContent
